/* src/App.css */
.app-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}

.header {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
}

.game-container {
  display: flex;
  flex-direction: row;
  gap: 20px;
  width: 100%;
}

.player-section {
  flex: 1;
  padding: 20px;
  border: 1px solid #ddd;
  background-color: #f9f9f9;
  display: flex;
  flex-direction: column;
}

.player-form {
  margin-bottom: 20px;
}

/* Set a consistent style for all inputs within the player-section */
.player-section input[type="text"],
.player-section input[type="number"] {
  width: 100%;
  padding: 5px;
  margin-bottom: 10px;
}

.player-list {
  flex: 1;
}

.game-section {
  flex: 3;
  padding: 20px;
  border: 1px solid #ddd;
  background-color: #fff;
}

button {
  padding: 10px 20px;
  border: none;
  background-color: #007BFF;
  color: white;
  font-size: 16px;
  cursor: pointer;
}

button:disabled {
  background-color: #ccc;
}

table {
  width: 100%;
  border-collapse: collapse;
}

th, td {
  border: 1px solid #ddd;
  padding: 10px;
  text-align: center;
}

.frame {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

input[type="text"] {
  width: 20px;
  height: 20px;
  text-align: center;
  font-size: 18px;
}

input[disabled] {
  background-color: #f9f9f9;
  cursor: not-allowed;
}

.frame-total {
  margin-top: 5px;
  font-weight: bold;
}

.winner {
  background-color: #b2f5b2; /* Light green background for the winning player */
}
